// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const usersnapProjectKey = 'd497b154-a77d-489d-8728-d76a6352b317';
export const usersnapGlobalKey = 'f2ad48c3-4c2d-46ae-8e67-047246286d11';
export const baseURI =
	'https://firebasestorage.googleapis.com/v0/b/anlar-dev.appspot.com/o/images%2F';
export const baseURI2 =
	'https://firebasestorage.googleapis.com/v0/b/anlar-dev.appspot.com/o/files%2F';
export const baseURI3 =
	'https://firebasestorage.googleapis.com/v0/b/anlar-dev.appspot.com/o/documents%2F';
// export const baseURI =
// 	'https://firebasestorage.googleapis.com/v0/b/anlar-test.appspot.com/o/images%2F';
// export const baseURI2 =
// 	'https://firebasestorage.googleapis.com/v0/b/anlar-test.appspot.com/o/files%2F';
// export const baseURI3 =
// 	'https://firebasestorage.googleapis.com/v0/b/anlar-dev.appspot.com/o/documents%2F';

export const hotLicense = 'd09f2-537f5-84715-6432b-5870b';

// export const re =
// 	/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
export const re =
	/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
