import PropTypes from 'prop-types';
import { createContext, useState, useContext, useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import { AppFunctions } from './AppContext';
import { documentTypes } from 'utils/dropdownOptions/options';
import { getCount, getListQuery, getSubCollection } from 'API/basicCalls';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { CLEAR_EDIT, RESET_OPP, SET_OPP } from 'store/actions';
import { re } from 'store/constant';
import { createDoc } from 'API/documentCalls';

const objectSchema = Yup.string().required('This field is required');

const schemas = {
	details: Yup.object().shape({
		client: Yup.object({ id: objectSchema, name: objectSchema }).required(
			'This field is required'
		),
		solicitationName: Yup.string().required('Solicitation Name is required'),
		solicitationNumber: Yup.string().max(100),
		description: Yup.string(),
		dateQnA: Yup.date(),
		dueDate: Yup.date().required('Due Date is required'),
		decisionDueDate: Yup.date(),
		linkToPosting: Yup.string().matches(re, 'Please enter a valid URL'),
		opportunityStatus: Yup.object({ id: objectSchema, name: objectSchema }).required(
			'This field is required'
		),
		opportunityOwner: Yup.object({ id: objectSchema, name: objectSchema }).required(
			'This field is required'
		),
		projectType: Yup.object({ id: objectSchema, name: objectSchema }).required(
			'This field is required'
		),
		gsaSchedule: Yup.string().max(150).required('GSA Schedule is required'),
		source: Yup.object({
			id: Yup.string(),
			name: Yup.string()
		}),
		bpa: Yup.object({
			id: Yup.string(),
			name: Yup.string()
		}),
		initialThoughts: Yup.string(),
		role: Yup.string().max(150).required('Role is required'),
		rfpManager: Yup.object({
			id: Yup.string(),
			name: Yup.string()
		}),
		subContractor: Yup.array(),
		endClient: Yup.object({ id: Yup.string(), name: Yup.string() })
	}),
	teams: Yup.object().shape({
		teamsInvolved: Yup.array().min(1).required('Teams Involved is required'),
		primaryTeam: Yup.object({ id: objectSchema, name: objectSchema }).required(
			'This field is required'
		)
	}),
	budget: Yup.object().shape({
		budgetType: Yup.string().max(50),
		estimatedBudgetAmountNotes: Yup.string().max(300),
		estimatedProjectLength: Yup.number().integer().min(0)
	})
};

const OpportunityContext = createContext(null);

export const OpportunityProvider = ({ children }) => {
	const { formSubmissionUpdate, formSubmissionCreate, uploadDocument, user } = useAuth();
	const { alert, setAlert, triggerRefresh } = AppFunctions();
	const opportunity = useSelector((state) => state.opportunity);
	const editedFields = opportunity.edited;
	const dispatch = useDispatch();

	const [activeStep, setActiveStep] = useState(0);
	const [edit, setEdit] = useState({
		teams: false,
		budget: false,
		details: false
	});
	const [newClient, setNewClient] = useState();
	const [back, setBack] = useState(false);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [companyModalOpen, setCompanyModalOpen] = useState(false);
	const [type, setType] = useState('details');
	const [newId, setNewId] = useState('');
	const [updateRFP, setUpdateRFP] = useState(false);

	let schema = schemas[type];

	useEffect(() => {
		schema = schemas[type];
	}, [type]);

	const formik = useFormik({
		initialValues: {
			...opportunity
		},
		validationSchema: schema,
		onSubmit: (values) => {
			handleSubmit(values);
		}
	});

	const handleSubmit = (values) => {
		dispatch({ type: SET_OPP, object: values });
		handleNext(opportunity);
	};

	const handleNext = async () => {
		setLoading(true);
		let editing = false;

		if (edit.teams || edit.budget || edit.details) {
			editing = true;
		}

		try {
			if (!editing && !back) {
				switch (activeStep) {
					case 0:
						formik.values.client = newClient ? newClient : formik.values.client;
						await handleCreate();
						break;
					case 1:
					case 2:
						await handleUpdate();
						break;
					case 3:
						setAlert({
							...alert,
							open: true,
							message: `Opportunity Created!`,
							alertSeverity: 'success'
						});
						triggerRefresh();
						dispatch({ type: RESET_OPP });
						break;
				}
				formik.setSubmitting(false);
				setActiveStep(activeStep + 1);
			} else {
				handleUpdate();
				formik.setSubmitting(false);
				setActiveStep(activeStep + 1);
			}
		} catch (error) {
			console.error(error);
		}
		setLoading(false);
	};

	const handleCreate = async () => {
		const teamIDArray = opportunity.teamsInvolved.map((team) => team.id);
		const data = await formSubmissionCreate('opportunities', {
			...opportunity,
			teamArray: teamIDArray
		});
		setNewId(data.data);
		dispatch({
			type: SET_OPP,
			object: { ...opportunity, id: data.data }
		});

		// //if the opportunity is a bpa, add subcollection to company/client for the bpa
		// if (data !== 'error' && opportunity.projectType.name.includes('New BPA')) {
		// 	await handleBPA(data.data);
		// }
	};

	const handleRFPUpdate = async () => {
		const rfps = await getListQuery('rfpContent', 'opportunity.id', opportunity.id, '==');

		for (const rfp of rfps) {
			await formSubmissionUpdate('rfpContent', {
				id: rfp.id,
				opportunity: {
					...rfp.opportunity,
					status: {
						id: opportunity.opportunityStatus.id,
						name: opportunity.opportunityStatus.name
					}
				}
			});
		}
		return;
	};

	const handleUpdate = async () => {
		delete opportunity.dateCreated;
		const teamIDArray = opportunity.teamsInvolved.map((team) => team.id);

		const res = await formSubmissionUpdate('opportunities', {
			...editedFields,
			id: opportunity.id,
			teamArray: teamIDArray
		});

		if (res !== 'error' && edit[type]) {
			setAlert({
				...alert,
				open: true,
				message: 'Opportunity Updated!',
				alertSeverity: 'success'
			});

			if (updateRFP) {
				await handleRFPUpdate();
			}

			edit[type] && handleEdit(type);
			dispatch({ type: CLEAR_EDIT });

			triggerRefresh();
		} else if (edit[type]) {
			setAlert({
				...alert,
				open: true,
				message: `Error Updating Opportunity`,
				alertSeverity: 'error'
			});
		}
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
		setBack(true);
	};

	const handleAdd = () => {
		setOpen(open ? false : true);
		dispatch({
			type: RESET_OPP
		});
	};

	const handleStartOver = () => {
		setActiveStep(0);
		dispatch({
			type: RESET_OPP
		});
		formik.resetForm();
	};

	const handleEdit = (key) => {
		setEdit({ [key]: edit[key] ? false : true });
		dispatch({
			type: CLEAR_EDIT
		});
	};

	const submitFiles = async (record, doc) => {
		let res;

		let documentUrl = await uploadDocument(doc);

		const data = {
			name: doc.name,
			documentName: doc.documentName,
			documentUrl,
			status: 'Active',
			subContractor: doc.subContractor || { id: '', name: '' },
			documentType: documentTypes.find((type) => type.value === doc.documentType),
			opportunityId: record ? record.id : newId,
			...(doc.startDate && { startDate: doc.startDate }),
			...(doc.endDate && { endDate: doc.endDate }),
			...(doc.amount && { amount: doc.amount }),
			addedBy: {
				id: user.id,
				name: user.name
			}
		};

		res = await createDoc(data);

		if (res !== 'error') {
			setAlert({
				...alert,
				open: true,
				message: `Upload Successful!`,
				alertSeverity: 'success'
			});

			// triggerRefresh();
		} else {
			setAlert({
				...alert,
				open: true,
				message: `Error uploading document(s)`,
				alertSeverity: 'error'
			});
		}
	};

	return (
		<OpportunityContext.Provider
			value={{
				formik,
				activeStep,
				setActiveStep,
				edit,
				setEdit,
				newClient,
				setNewClient,
				handleNext,
				setBack,
				type,
				setType,
				schema,
				open,
				handleAdd,
				handleStartOver,
				handleEdit,
				setLoading,
				loading,
				companyModalOpen,
				setCompanyModalOpen,
				handleBack,
				loading,
				submitFiles,
				setNewId,
				setUpdateRFP,
				handleSubmit
			}}
		>
			{children}
		</OpportunityContext.Provider>
	);
};

OpportunityProvider.propTypes = {
	children: PropTypes.node
};

export default OpportunityContext;

export const OpportunityFunctions = () => {
	return useContext(OpportunityContext);
};
